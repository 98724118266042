import React from 'react';
import * as AppleAuthentication from 'expo-apple-authentication';
import AppleSignInButton from '../AppleSignInButton';
import Storage from '../../storage';
import {useAppleSignInMutation} from '../../graphql-types';
import {useToken} from '../../hooks/useTokenContext';
import {useNotification} from '../../hooks/useNotificationContext';

export default function AppleSignIn() {
  const [appleSignIn] = useAppleSignInMutation();
  const {set: setToken} = useToken();
  const {set: setNotification} = useNotification();

  return (
    <AppleSignInButton
      onPress={async () => {
        try {
          const credential = await AppleAuthentication.signInAsync({
            requestedScopes: [
              AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
              AppleAuthentication.AppleAuthenticationScope.EMAIL,
            ],
          });

          if (
            credential.email &&
            credential.fullName?.familyName &&
            credential.fullName.givenName
          ) {
            await Storage.saveAppleSignInInfo({
              name:
                credential.fullName.givenName +
                ' ' +
                credential.fullName.familyName,
              email: credential.email,
            });
          }

          const info = await Storage.getAppleSignInInfo();

          const {data} = await appleSignIn({
            variables: {
              input: {
                identityToken: credential.identityToken,
                ...info,
              },
            },
          });

          if (data?.appleSignIn.accessToken) {
            setToken(data.appleSignIn.accessToken);
          }
        } catch (e) {
          setNotification({
            type: 'error',
            message: 'Failed to sign in with apple.',
          });
        }
      }}
    />
  );
}
