import React, {useContext, PropsWithChildren, useState} from 'react';
import {View, Platform, Text} from 'react-native';
import styled from 'styled-components/native';
import {TouchableOpacity} from 'react-native';

type SetError = (error: any) => void;
type ClearError = () => void;

type ErrorContext = {
  error: any;
  set: SetError;
  clear: ClearError;
};

const ErrorContext = React.createContext<ErrorContext>({
  error: undefined,
  set: _ => {},
  clear: () => {},
});

const Error = styled(View)`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: red;
`;

const ErrorProvider = ({children, ...props}: PropsWithChildren<{}>) => {
  const [state, setState] = useState<string | undefined>(undefined);

  const set: SetError = value => {
    if (value) {
      setState(value);
    }
  };

  const clear: ClearError = () => {
    setState(undefined);
  };

  return (
    <>
      {state ? (
        <Error>
          <Text>Error Occured</Text>
          <Text>It has been reported.</Text>
          <TouchableOpacity onPress={() => clear()}>
            <Text>Click here to try again.</Text>
          </TouchableOpacity>
        </Error>
      ) : null}
      <ErrorContext.Provider value={{error: state, set, clear}} {...props}>
        {children}
      </ErrorContext.Provider>
    </>
  );
};

const useError = () => useContext(ErrorContext);

export {ErrorProvider, useError};
