import React, {useEffect} from 'react';
import FacebookSignInButton from '../FacebookSignInButton';
import {useToken} from '../../hooks/useTokenContext';
import * as Facebook from 'expo-auth-session/providers/facebook';
import {ResponseType} from 'expo-auth-session';
import {useFacebookSignInMutation} from '../../graphql-types';
import Constants from 'expo-constants';
import {useNotification} from '../../hooks/useNotificationContext';

const {facebook} = Constants.manifest.extra;

export default function FacebookSignIn() {
  const {set: setNotification} = useNotification();
  const [signInUser] = useFacebookSignInMutation({
    fetchPolicy: 'no-cache',
  });

  const [request, response, promptAsync] = Facebook.useAuthRequest({
    responseType: ResponseType.Token,
    clientId: facebook.appId,
    scopes: ['email'],
  });

  const {set: setToken} = useToken();

  const signIn = async (accessToken: string) => {
    try {
      const {data} = await signInUser({
        variables: {
          accessToken: accessToken,
        },
      });

      if (data?.facebookSignIn?.user && data?.facebookSignIn?.accessToken) {
        setToken(data.facebookSignIn.accessToken);
      }
    } catch (e) {
      setNotification({
        type: 'error',
        message: 'Please try again later.',
      });
    }
  };

  useEffect(() => {
    if (response?.type === 'success') {
      if (response?.authentication?.accessToken) {
        signIn(response.authentication.accessToken);
      }
    }

    if (response && response.type != 'success') {
      setNotification({
        type: 'error',
        message: 'Failed to sign in with Facebook.',
      });
    }
  }, [response]);

  if (!request) {
    return null;
  }

  return <FacebookSignInButton onPress={() => promptAsync()} />;
}
