import React, {useState, useEffect} from 'react';
import * as Device from 'expo-device';
import Mobile from './mobile';
import Desktop from './desktop';
import Bridge from './components/Bridge';
import WebLoading from './components/WebLoading';
import {Platform} from 'react-native';

export default function App() {
  const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
    false,
  );

  useEffect(() => {
    const getDeviceType = async () => {
      let result = await Device.getDeviceTypeAsync();
      setDeviceType(result);
    };

    getDeviceType();
  }, []);

  if (!deviceType) {
    return Platform.select({
      web: <WebLoading />,
      default: null,
    });
  }

  return (
    <Bridge>
      {deviceType === Device.DeviceType.DESKTOP ? <Desktop /> : <Mobile />}
    </Bridge>
  );
}
