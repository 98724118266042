import {ApolloClient, InMemoryCache, ApolloLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {CachePersistor} from 'apollo3-cache-persist';
import {createUploadLink} from 'apollo-upload-client';

const SCHEMA_VERSION = '2';

const {graphql} = Constants.manifest.extra;

export * from './queries';
export * from './fragments';
export * from './mutations';

export default async function setupGraphQL(token: string | undefined) {
  const uploadLink = createUploadLink({
    uri: graphql.endpoint,
  });

  const authLink = setContext(async (operation, {headers}) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          beach(_, {args, toReference}) {
            if (!args) {
              return;
            }

            return toReference({
              __typename: 'Beach',
              id: args.id,
            });
          },
          parameter(_, {args, toReference}) {
            if (!args) {
              return;
            }

            return toReference({
              __typename: 'Parameter',
              id: args.id,
            });
          },
        },
      },
    },
  });

  const persistor = new CachePersistor({
    cache,
    storage: AsyncStorage,
  });

  const currentVersion = await AsyncStorage.getItem('@schema-version');

  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore();
  } else {
    await persistor.purge();
    await AsyncStorage.setItem('@schema-version', SCHEMA_VERSION);
  }

  const client = new ApolloClient({
    link: ApolloLink.from([
      authLink.concat(uploadLink),
    ]),
    connectToDevTools: true,
    cache,
  });

  return {client, persistor};
}
