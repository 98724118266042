import React, {useEffect, useState} from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import {useLayerSelection} from '../../hooks/useLayerSelectionContext';
import styled from 'styled-components';
import styledn from 'styled-components/native';
import Text from '../components/Text';
import constants from '../constants/constants';
import colors from '../constants/colors';
import CancelIcon from '../assets/images/cancel-icon.png';
import * as Device from 'expo-device';


const BannerBox = styledn(View)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (!isDesktop) {
      return `
  		position: fixed; /* could be absolute */
  		top: 20%; left: 0; bottom: 0; right: 0;
  		margin-left: auto; margin-right: auto;
  		width: 80%;		/* only difference between the two */
  		height: min-content;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
		background-color: ${colors.mote.primary.seafoamWhite};
		padding: 5px 10px 10px 10px;
		border-radius: 5px;
		border: 2px solid ${colors.mote.primary.powderBlue}; 
		z-index: 10000;
      `;
    }

    return `
  	  position: fixed; /* could be absolute */
  	  top: 20%; left: 0; bottom: 0; right: 0;
  	  margin-left: auto; margin-right: auto;
  	  width: 30%;
  	  height: min-content;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
	  background-color: ${colors.mote.primary.seafoamWhite};
	  padding: 5px 10px 10px 10px;
	  border-radius: 5px;
      border: 2px solid ${colors.mote.primary.powderBlue}; 
      z-index: 10000;
    `;
  }}
`;

const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 10px;
`;

const CloseText = styled.div`
  flex-grow: 1;
  text-align: right;
  font-family: tablet-gothic;
`;

const CloseButton = styled.a`
  display: flex;
  flex-direction: row;
  text-direction: none;
`;

const TitleText = styled.div`
  flex-grow: 1;
  text-align: left;
  font-family: tablet-gothic;
  padding-bottom: 5px;
  font-size: 1.1em;
`;

/*
	This code is used to put a temporary banner on screen for particular events.
	The user has to dismiss the banner box to continue. Banners are meant for system
	issues, news events (weather), etc. Not as a regular thing. Comment the use of Banner
	in or out in components/mapped-reports/index.tsx. Change the banner text below. */
export default function Banner() {
	const [showBanner, setShowBanner] = useState(true);
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

	useEffect(() => {
		const getDeviceType = async () => {
	  		const type = await Device.getDeviceTypeAsync();
	  		setDeviceType(type);
		};

		getDeviceType();
	});
		
	return (
	  <>
	  {showBanner ? ( 
	  <BannerBox isDesktop={deviceType == Device.DeviceType.DESKTOP}>
	  		<CloseButton
        		href="#"
        		onClick={e => {
          		e.preventDefault();
          		setShowBanner(false);
        	}}>
        <CloseText></CloseText>
        <CloseIcon src={CancelIcon} />
      </CloseButton>

	  	<TitleText>Service Advisory: Storm Notice</TitleText>

		{/* Edit text below to match what you need in the banner.*/}
		<Text>
		Due to recent tropical storm activity, some conditions reports may be delayed or outdated. 
		The safety of our volunteer Beach Ambassadors is our top priority. Regular reporting will 
		resume as soon as it is safe to do so.
		</Text>
		<Text>&nbsp;</Text>
		<Text>
		If you are in an affected area, please refrain from visiting the beach or swimming until 
		local authorities have confirmed it is safe. We appreciate your patience and understanding 
		during this time.
		</Text>
	  </BannerBox>
	  ) : null}
	  </>
	);
}
